import {IGatsbyImageData} from 'gatsby-plugin-image';
import {AllImages} from '../components/QAllImages/__generated__/AllImages';
import {AllData_allFile_edges, AllData_allFile_edges_node} from './__generated__/AllData';


export const getImageData = (name: string | null, allData: AllImages): IGatsbyImageData => {
  const gatsbyImageData: IGatsbyImageData | null = allData
      .allFile
      .edges
      .map((edge: AllData_allFile_edges) => edge.node)
      .find((node: AllData_allFile_edges_node) => node.name == name)
      ?.childrenImageSharp
      ?.[0]
      ?.gatsbyImageData ?? null;

  if (gatsbyImageData === null) {
    throw new Error(`Cannot find required imageName with name: ${name}. Available images=${allData
        .allFile
        .edges
        .map((edge: AllData_allFile_edges) => edge.node).map((node) => node.name)}`);
  }

  return gatsbyImageData;
};
