import {graphql, StaticQuery} from 'gatsby';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {SiteData} from './__generated__/SiteData';

const query = graphql`
  query SiteData {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const PageHead: React.VoidFunctionComponent = () =>
  <StaticQuery query={query} render={(siteData: SiteData) =>
    <Helmet title={siteData.site?.siteMetadata?.title ?? ''}>
      <link rel="icon" type="image/png" href="/favicon.png" />
    </Helmet>}
  />;
